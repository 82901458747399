<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>

    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-row class="match-height">
        <div class="col-md-12">
          <div>
            <div class="card p-2">
              <router-link :to="{name : 'admin-payment'}">
                <b-button
                  variant="primary"
                  class="mr-50 col-md-2"
                >
                  {{ $t('returnToList') }}
                </b-button>
              </router-link>
            </div>
          </div>
        </div>
      </b-row>
      <b-row
        class="match-height"
      >
        <div class="col-md-7">
          <div>
            <div class="card p-2">
              <h4 class="font-weight-bolder mb-1">
                {{ $t('payShippingFee') }}
              </h4>
              <h4 class="font-weight-bolder mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-map-pin"
                ><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle
                  cx="12"
                  cy="10"
                  r="3"
                /></svg>{{ $t('recipientAddress') }}
              </h4>

              <div>
                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('firstName') + '-' + $t('lastName') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ arritem.address.fullname }}
                  </div>
                </div>

                <div class="d-flex mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ arritem.address.address }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('mobileNumber') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ arritem.address.tel }}
                  </div>
                </div>

                <div class="d-flex mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('deliveryType') }}
                    </p>
                  </div>

                  <div class="w-50">
                    <span class="d-flex">{{ arritem.typedelivery.pro_name }}<p style="color:red;"> ({{ arritem.typedelivery.paydelivery }})</p></span>
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('sender') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ arritem.delivery.name }}
                  </div>
                </div>

                <div
                  v-if="arritem.delivery.id === 2"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ $t('receivedNotseeprice') }}
                  </div>
                </div>

                <div
                  v-if="arritem.delivery.id === 3"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ arritem.delivery.address }}
                  </div>
                </div>
                <div
                  v-if="arritem.delivery.id === 3"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('mobileNumber') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ arritem.delivery.tel }}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div>
            <div class="card p-2">
              <h4 class="font-weight-bolder mb-1">
                {{ $t('Payment') }}
              </h4>

              <div>
                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('orderNumber') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ arritem.itemnumber_text }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('customerCode') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ arritem.username }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('totalPaid') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ Commas(arritem.payment) }} ฿
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('paymentType') }}
                    </p>
                  </div>

                  <div class="w-50 badge badge-light-primary col-md-3">
                    {{ $t('useSystemBalance') }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('status') }}
                    </p>
                  </div>

                  <div
                    v-if="arritem.payNow === 0"
                    class="w-50 badge badge-light-danger col-md-3"
                  >
                    {{ $t('awaitingPayment') }}
                  </div>
                  <div
                    v-if="arritem.payNow === 1"
                    class="w-50 badge badge-light-primary col-md-3"
                  >
                    {{ $t('pendingReview') }}
                  </div>
                  <div
                    v-if="arritem.payNow === 2 && arritem.order_out === 1"
                    class="w-50 badge badge-light-danger col-md-3"
                  >
                    {{ $t('awaitingPickup') }}
                  </div>
                  <div
                    v-if="arritem.payNow === 2 && arritem.order_out === 2"
                    class="w-50 badge badge-light-success col-md-3"
                  >
                    {{ $t('receivedItem') }}
                  </div>
                  <div
                    v-if="arritem.payNow === 2 && arritem.order_thaiout === 1"
                    class="w-50 badge badge-light-primary col-md-3"
                  >
                    {{ $t('awaitingDeliveryTH') }}
                  </div>
                  <div
                    v-if="arritem.payNow === 2 && arritem.order_thaiout === 2"
                    class="w-50 badge badge-light-success col-md-3"
                  >
                    {{ $t('delivered') }}

                  </div>
                  <div
                    v-if="arritem.payNow === 3"
                    class="w-50 badge badge-light-secondary col-md-3"
                  >
                    {{ $t('cancelled') }}
                  </div>
                  <p
                    v-if="arritem.payNow === 3"
                    style="color:red;"
                  >
                    *{{ $t('canceledBy') }} {{ arritem.by_Cancel }}
                  </p>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('remarks') }}
                    </p>
                  </div>

                  <div class="w-50">
                    <p>{{ arritem.remark ? arritem.remark: '-' }}</p>
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <b-button
                    v-if="arritem.payNow === 0"
                    variant="warning"
                    class="mt-1 mr-50 col-md-6"
                    @click="checkpay(arritem)"
                  >
                    {{ $t('makePayment') }}
                    <!-- v-model="payNow" -->
                  </b-button>
                  <b-button
                    v-if="arritem.payNow === 1"
                    variant="success"
                    class="mt-1 mr-50 col-md-6"
                    @click="checkapprove(arritem)"
                  >
                    {{ $t('approve') }}
                  </b-button>
                  <b-button
                    v-if="arritem.payNow === 0 || arritem.payNow === 1"
                    variant="danger"
                    class="mt-1 mr-50 col-md-6"
                    @click="checkcancel(arritem)"
                  >
                    {{ $t('cancelOrder') }}
                  </b-button>
                  <b-button
                    v-if="arritem.payNow === 2"
                    variant="danger"
                    class="mt-1 mr-50 col-md-12"
                    @click="checkcancel(arritem)"
                  >
                    {{ $t('cancelOrder') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="font-weight-bolder">
                  {{ $t('parcelCodeList') }}
                </h4>
              </div>
            </div>
            <div class="p-2 table-container">
              <table class="styled-table mt-n25">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="p-2"
                    >
                      {{ $t('lotSequence') }}
                    </th>
                    <th scope="col">
                      {{ $t('parcelCode') }}
                    </th>
                    <th scope="col">
                      {{ $t('orderList') }}
                    </th>
                    <th scope="col">
                      {{ $t('remarks') }}
                    </th>
                    <th scope="col">
                      {{ $t('quantity') }}
                    </th>
                    <th scope="col">
                      {{ $t('weight') }}
                    </th>
                    <th scope="col">
                      {{ $t('width') }}
                    </th>
                    <th scope="col">
                      {{ $t('height') }}
                    </th>
                    <th scope="col">
                      {{ $t('length') }}
                    </th>
                    <th scope="col">
                      {{ $t('cubic') }}
                    </th>
                    <th scope="col">
                      {{ $t('arrange') }}
                    </th>
                    <th
                      scope="col"
                    >
                      {{ $t('type') }}
                    </th>
                    <th scope="col">
                      {{ $t('serviceFee') }}
                    </th>
                    <th scope="col">
                      {{ $t('pricePerKilo') }}
                    </th>
                    <th scope="col">
                      {{ $t('pricePerCubic') }}
                    </th>
                  </tr>
                </thead>
                <thead class="text-center">
                  <tr style="background: #fff; color: black;">
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b />
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b />
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b />
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('total') }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ Commas(postnumber.reduce((total,item) => total + item.product_amount,0)) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ Commas(postnumber.reduce((total,item) => total + (item.weight*item.product_amount),0)) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ Commas(postnumber.reduce((total,item) => total + item.width,0)) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ Commas(postnumber.reduce((total,item) => total + item.height,0)) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ Commas(postnumber.reduce((total,item) => total + item.long,0)) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ CommasCue(postnumber.reduce((total,item) => total + (item.charge_cue*item.product_amount),0)) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #fff"
                    />
                    <th
                      class="text-center"
                      style="background: #fff; color: black;"
                    >
                      <b />
                    </th>
                    <th
                      class="text-center"
                      style="background: #fff; color: black;"
                    >
                      {{ sumall = Commas(postnumber.reduce((total,item) => total + (item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee),0)) }}
                      <b />
                    </th>
                    <th
                      class="text-center"
                      style="background: #fff; color: black;"
                    >
                      {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_kilo === item.charge_per_save && item.discount ? item.amount_discount: 0) + (!item.discount && item.charge_per_kilo === item.charge_per_save ? item.charge_per_kilo : 0),0)) }}
                      <!-- {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_kilo === item.charge_per_save ? item.charge_per_save: 0 ),0)) }} -->
                      <b />
                    </th>
                    <th
                      class="text-center"
                      style="background: #fff; color: black;"
                    >
                      {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_cue === item.charge_per_save && item.discount ? item.amount_discount: 0) + (!item.discount && item.charge_per_cue === item.charge_per_save ? item.charge_per_cue : 0),0)) }}
                      <!-- {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_cue === item.charge_per_save ? item.charge_per_save: 0 ),0) ) }} -->
                      <b />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,index) in postnumber"
                    :key="index"
                    :class="`ctx-${item.status}`"
                  >
                    <td
                      scope="col"
                      class="text-center"
                    >
                      <i
                        v-if="item.transportType === 1"
                        class="fas fa-truck-moving"
                      />
                      <i
                        v-if="item.transportType === 2"
                        class="fas fa-ship"
                      />
                      <router-link
                        :to="{name : 'admin-warehouse-edit', params: {id: item.lot_name}}"
                        target="_blank"
                      >
                        <span>{{ item.lot_name ? item.lot_name: '' }}</span>
                      </router-link>{{ item.lot_order ? `/${item.lot_order}`: '' }}
                    <!-- <b-form-checkbox
                      v-if="item.out_thaiwarehouse"
                      v-model="item.select"
                      @input="checkboxselect(item.select,item)"
                    />
                    {{ selectItem[index] }} -->
                    </td>
                    <td scope="col">
                      {{ item.postnumber }}
                    <!-- <feather-icon
                      icon="Edit3Icon"
                      class="mr-50 text-primary cursor-pointer"
                      @click="EditType(item)"
                    /> -->
                    </td>
                    <td scope="col">
                      <router-link
                        v-if="item.order_id"
                        :to="{name : 'admin-order-detail', params: {id: item.order_id}}"
                        target="_blank"
                      >
                        <span>{{ item.order_text ? `ORD-${item.order_text}`: '-' }}</span>
                      </router-link>
                      <span v-else>-</span>
                      <!-- order_text  order_id-->
                      <!-- {{ item.tag_id ? item.tag_id: '-' }} -->
                    </td>
                    <td
                      scope="col"
                      class="text-left"
                    >
                      {{ item.remark ? item.remark: '-' }}
                    <!-- <router-link :to="{name : 'admin-tracking-edit', params: { id: item._id }}">
                      {{ item.postnumber ? item.postnumber: '-' }}
                    </router-link> -->
                    </td>
                    <td scope="col">
                      {{ Commas(item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.weight*item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.width) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.height) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.long) }}
                    </td>
                    <td scope="col">
                      {{ CommasCue(item.charge_cue*item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ item.collocate_wh_name ? item.collocate_wh_name: '-' }}
                    </td>
                    <td scope="col">
                      {{ item.product_type ? item.product_type.pro_initial: '-' }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee) }}
                    </td>
                    <td scope="col">
                      <span v-if="item.discount && item.charge_per_kilo === item.charge_per_save">
                        {{ Commas(item.charge_per_kilo) }}<span class="text-danger">-{{ Commas(item.discount) }}% = {{ Commas(item.amount_discount) }}
                        </span>
                      </span>
                      <span v-else>{{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</span>
                      <!-- {{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }} -->
                    </td>
                    <td scope="col">
                      <span v-if="item.discount && item.charge_per_cue === item.charge_per_save">
                        {{ Commas(item.charge_per_cue) }}<span class="text-danger">-{{ Commas(item.discount) }}% = {{ Commas(item.amount_discount) }}
                        </span>
                      </span>
                      <span v-else>{{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</span>
                      <!-- {{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }} -->
                    </td>
                  </tr>
                </tbody>
                <tfoot class="text-center">
                  <tr>
                    <th
                      class="text-right"
                      colspan="12"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('total') }}</b>
                    </th>
                    <th
                      class="text-right"
                      colspan="3"
                      style="background: #fff; color: black;"
                    >
                      <b
                        v-if="arritem.coupon"
                        class="mr-2"
                      >
                        <b style="text-decoration: line-through; color: red;">{{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                          (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿</b>
                        {{ Commas((postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                          (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)- arritem.coupon.discount_coupon)) }} ฿

                        <!--  - arritem.coupon.discount -->
                      </b>
                      <b
                        v-else
                        class="mr-2"
                      >{{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                        (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿</b>
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="text-right"
                      colspan="12"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('discountCoupon') }}</b>
                    </th>
                    <th
                      class="text-right"
                      colspan="3"
                      style="background: #fff; color: black;"
                    >
                      <b
                        v-if="arritem.coupon"
                        class="mr-2"
                        style="color: red; "
                      >{{ Commas(arritem.coupon.discount_coupon) }} ฿</b>
                      <b
                        v-else
                        class="mr-2"
                      >0 ฿</b>
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="text-right"
                      colspan="12"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('thaiWarehouseFee') }}</b>
                    </th>
                    <th
                      class="text-right"
                      colspan="3"
                      style="background: #fff; color: black;"
                    >
                      <b class="mr-2">{{ Commas(arritem.Service_fee) }} ฿</b>
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="text-right"
                      colspan="12"
                      style="background: #fff; color: black;"
                    >
                      <b style="color:red;">{{ $t('estimatedCostNote') }}<span style="color:rgb(0, 0, 0);"> {{ $t('domesticDeliveryFee') }} </span></b>
                    </th>
                    <th
                      class="text-right"
                      colspan="3"
                      style="background: #fff; color: black;"
                    >
                      <b
                        v-if="arritem.promotion"
                      >
                        <b
                          style="text-decoration: line-through; color: red;"
                        >{{ Commas(arritem.promotion.old_delivery) }} ฿</b>
                        <b class="mr-2"> {{ Commas(arritem.promotion.discount_pro) }} ฿</b>
                      </b>

                      <b
                        v-else
                        class="mr-2"
                      >{{ Commas(arritem.Shipping_cost) }} ฿ </b>
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="text-right"
                      colspan="12"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('netAmount') }}</b>
                    </th>
                    <th
                      class="text-right"
                      colspan="3"
                      style="background: #fff; color: black;"
                    >
                      <b class="mr-2">{{ Commas(arritem.payment) }} ฿</b>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="d-flex align-items-center justify-content-start p-2" />

          </div>
        </b-col>

      </b-row>

      <b-row class="match-height">
        <div class="col-md-6">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <h4 class="font-weight-bolder">
                  {{ $t('relatedAmountList') }}
                </h4>
              </div>

              <table class="styled-table mt-n25">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ $t('transactionDate') }}
                    </th>
                    <th
                      class="text-left"
                      scope="col"
                    >
                      {{ $t('details') }}
                    </th>
                    <th scope="col">
                      {{ $t('quantity') }}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr
                    v-for="(item,index) in record"
                    :key="index"
                    style="background: #fff; color: black;"
                  >
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      {{ time(item.created_at) }}
                      <!-- 09 ตุลาคม 2023 13:16:12 -->
                    </th>
                    <th
                      class="text-left"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      {{ item.detail }}
                      <!-- รายการชำระค่านำเข้า PAY-10727 -->
                    </th>
                    <th
                      class="text-right"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <span :style="getAmountStyle(item.type)">
                        {{ getAmountPrefix(item.type) }} {{ Commas(item.amount.toFixed(2)) }} ฿
                      </span>
                      <!-- {{ item.amount }} -->
                      <!-- -9.00 ฿ -->
                    </th>
                  </tr>
                </thead>
              </table>
              <div class="d-flex align-items-center justify-content-start p-2" />
            </div>
          </div>
        </div>
      </b-row>

    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import {
  BRow,
  BCol,
  BButton,
  // BFormInput,
  VBToggle,
  // BInputGroupAppend,
  // BInputGroup,
  BOverlay,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    // BFormInput,
    BButton,
    // BFormCheckbox,
    // vSelect,
    // BInputGroupAppend,
    // BInputGroup,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      type: 'A',
      itemsSelect: [
        { value: 'A' },
        { value: 'B' },
        { value: 'C' },
        { value: 'D' },
        { value: 'D1' },
        { value: 'S' },
      ],
      isActive: true,
      selectAll: [],
      selectItem: { key: 'checkbox', value: false },
      x: [],
      option: [],
      selectCustomer: null,
      getoption: [],
      items: [],
      ProductTypeitem: [],
      numberlength: 0,
      payment: 0,
      arritems: [],
      checkitems: 0,
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      showOver: false,
      ID: this.$route.params.id,
      arritem: [],
      postnumber: [],
      record: [],
      payNow: null,
      sumall: 0,
      // items: [
      //   {
      //     id: 1,
      //     name: 'John',
      //     age: 20,
      //     // status: 'orange',
      //   },
      //   {
      //     id: 2,
      //     name: 'Jane',
      //     age: 24,
      //     status: 'green',
      //   },
      //   {
      //     id: 3,
      //     name: 'Susan',
      //     age: 16,
      //     // status: 'red',
      //   },
      //   {
      //     id: 4,
      //     name: 'Chris',
      //     age: 55,
      //     // status: 'purple',
      //   },
      //   {
      //     id: 5,
      //     name: 'Dan',
      //     age: 40,
      //     // status: 'yellow',
      //   },
      // ],
    }
  },
  computed: {
  },
  watch: {
    selectItem: {
      handler() {
        this.updateCheckAllState()
      },
      deep: true,
    },
  },
  mounted() {
    this.getData()
    this.getbalance()
    this.getUserall()
    // this.GetData(this.$route.params.id)
    this.sub_role = []
    // this.GetSubrole()
    // this.GetModuleList()
    // this.GetGroupUserList()
  },
  methods: {
    toggleAll() {
      for (let i = 0; i < this.items.length; i + 1) {
        this.selectItem[i] = this.selectAll
      }
    },
    updateCheckAllState() {
      const checkedCount = this.selectItem.filter(item => item).length
      this.selectAll = checkedCount === this.selectItem.length
    },
    time(data) {
      moment.locale('th')
      return moment(data).tz('Asia/Bangkok').format('DD MMMM YYYY HH:mm:ss')
    },
    timeOk(data) {
      return moment(data).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
    },
    getUserall() {
      this.showOver = true
      try {
        this.$http.get('/Paymentuser/getcustomer')
          .then(response => {
            this.showOver = false
            this.option = response.data.data
            // console.log(this.option)
          }).catch(err => {
            console.log(err)
            this.showOver = false
          })
      } catch (e) {
        console.log(e)
      }
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16].includes(amount)) {
          return { color: 'green' }
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: 'red' }
        }
      }
      return { color: 'orange' }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16].includes(value)) {
          return '+'
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return '-'
        }
      }
      return ''
    },
    checkpay(data) {
      this.$bvModal.msgBoxConfirm('ต้องการชำระเงินให้ลูกค้าใช่หรือไม่?', {
        title: 'ยืนยันการชำระเงิน',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
              uid: data.uid,
            }
            this.$http.post('/StatusPayment/update', params)
              .then(() => {
                this.Success('ชำระค่านำเข้าเรียบร้อย')
                this.getData()
                this.getbalance()
              })
              .catch(error => {
                this.SwalError(error.response.data.message)
                // this.SwalError(error)
              })
          }
        })
    },
    checkapprove(data) {
      const obj = {
        // eslint-disable-next-line no-underscore-dangle
        id: data._id,
        dateokay: this.timeOk(new Date()),
      }
      this.$http.post('/StatusPayment/updatepay', obj)
        .then(() => {
          this.Success('อนุมัติรายการชำระค่านำเข้าเรียบร้อย')
          this.getData()
        })
        .catch(error => {
          this.SwalError(error)
        })
    },
    checkcancel(data) {
      this.$bvModal.msgBoxConfirm(`ยกเลิกรายการ ${data.itemnumber_text} ใช่หรือไม่`, {
        title: 'ต้องการยกเลิกรายการ',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
              uid: data.uid,
            }
            if (data.payNow === 0) {
              if (data.receipt) {
                params.receipt = false
              }
            }

            this.$http.post('/StatusPayment/Cancelpay', params)
              .then(() => {
                this.Success('ยกเลิกเรียบร้อย')
                this.getData()
                this.getbalance()
              })
              .catch(error => {
                this.SwalError(error.response.data.message)
                // this.SwalError(error)
              })
          }
        })
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/StatusPayment/getDataOne', { params })
        this.arritem = res
        this.getPostnumber(this.arritem)
      } catch (e) {
        console.log(e)
      }
    },
    getPostnumber(item) {
      const params = {
        data: item.postnumber_id,
      }

      this.$http.get('/StatusPayment/getPostnumber', { params })
        .then(response => {
          this.postnumber = response.data
        })
        .catch(error => console.log(error))
    },
    async getbalance() {
      const params = {
        id: this.ID,
      }
      this.$http.get('/StatusPayment/getBalance', { params })
        .then(response => {
          this.record = response.data
        })
        .catch(error => console.log(error))
    },
    async searchCustomer(item) {
      this.showOver = true
      this.getoption = []
      this.getoption = item
      try {
        const obj = {
          uid: item.uid,
        }
        this.$http.post('/Paymentuser/getpostnumber', obj)
          .then(response => {
            this.showOver = false
            // console.log(response.data)

            // eslint-disable-next-line no-shadow
            const res = response.data.map(item => {
              // eslint-disable-next-line no-param-reassign
              item.select = false
              // console.log(item)
              return item
            })
            this.items = res
            this.checkitems = 1
            // console.log(this.items)
            // this.arritem = this.items.forEach(ele => ele.transportType)
            // this.selectItem = this.items.length
            // this.getProduct()
            // console.log(this.items)
            // console.log(this.selectItem)
          })
          .catch(error => console.log(error))
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    async getProduct() {
      this.$http
        .get('/groupbyuser/show')
        .then(response => {
          if (response.data.success) {
            const Res = response.data.data
            // console.log(ele.transportType)
            this.ProductTypeitem = []
            // eslint-disable-next-line no-restricted-syntax
            for (const items of Res.product_type.filter(item => item.product_type_class)) {
              this.ProductTypeitem.push({
                pro_id: items.product_type_id, pro_name: items.product_type_name, pro_class: items.product_type_class, pro_kilo: items.product_type_kilo, pro_queue: items.product_type_queue,
              })
            }
            // console.log(this.ProductTypeitem)
            this.ProductTypeitem.sort((a, b) => {
              if (a.pro_name < b.pro_name) {
                return -1
              } if (a.pro_name > b.pro_name) {
                return 1
              }
              return 0
            })
            // eslint-disable-next-line no-restricted-syntax
          }
        })
        .catch(error => console.log(error))
    },
    async reitem(item) {
      try {
        const { data: res } = await this.$http.post('/postnumber/update', item)
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    filteredProductTypeItems(item) {
      if (!item) {
        // console.log(this.ProductTypeitem)
        return []
      }
      const arr = Number(item.pro_class)
      return this.ProductTypeitem.filter(ele => ele.pro_class === arr)
    },
    EditType(item) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-tracking-edit', params: { id: item._id } })
    },
    Createpayment(item) {
      if (this.checkitems === 0) {
        this.Alert(false, 'กรุณาเลือกลูกค้า')
      }
      if (this.checkitems === 1) {
        this.Alert(false, 'เลือกพัสดุ')
      }
      if (this.arritems.length) {
        this.$router.push({ name: 'admin-payment-create', params: { id: item[0].uid, arritems: this.arritems } })
      }
      // console.log(item)
      // console.log(this.arritems)
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'warning' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-warning',
        },
      })
    },
    countproducts() {
      return this.items.reduce((total, item) => total + item.product_amount, 0)
    },
    countweight() {
      return this.items.reduce((total, item) => total + (item.weight), 0)
    },
    countwidth() {
      return this.items.reduce((total, item) => total + (item.width), 0)
    },
    countheight() {
      return this.items.reduce((total, item) => total + (item.height), 0)
    },
    countproductLong() {
      return this.items.reduce((total, item) => total + (item.long), 0)
    },
    countquantity() {
      return this.items.reduce((total, item) => total + (item.charge_cue * item.product_amount), 0)
    },
    conutkilo() {
      const arrkilo = []
      this.items.forEach(ele => {
        if (ele.charge_per_kilo === ele.charge_per_save) {
          arrkilo.push(ele.charge_per_kilo)
        }
      })
      return arrkilo.reduce((total, item) => total + item, 0)
    },
    conutcue() {
      const arrcue = []
      this.items.forEach(ele => {
        if (ele.charge_per_cue === ele.charge_per_save) {
          arrcue.push(ele.charge_per_cue)
        }
      })
      return arrcue.reduce((total, item) => total + item, 0)
    },
    checkboxselect(check, item) {
      if (check) {
        this.numberlength += 1
        this.payment += item.charge_per_save
        // eslint-disable-next-line no-underscore-dangle
        this.arritems.push(item)
        this.checkitems = 2
      } else {
        const index = this.arritems.indexOf(item)
        if (index !== -1) {
          this.arritems.splice(index, 1)
        }
        this.numberlength -= 1
        this.payment -= item.charge_per_save
      }
      if (!this.arritems.length) {
        this.checkitems = 1
      }
    },
    isIndeterminate(event) {
      // console.log(event[0])
      this.items.map(item => {
        if (event[0]) {
          // eslint-disable-next-line no-param-reassign
          item.select = true
        } else {
          // eslint-disable-next-line no-param-reassign
          item.select = false
        }
        return item
      })
      // if (selectAll === true) {
      //   // this.checkboxselect(selectAll, this.items)

      //   this.selectItem.forEach(ele => {
      //     console.log(ele)
      //     // if (ele.out_thaiwarehouse) {
      //     // this.checkboxselect(selectAll, ele)
      //     // this.selectItem =
      //     //   console.log(this.selectItem)
      //     // }
      //   })

      //   // console.log(this.items)
      // } else {
      //   this.numberlength = 0
      //   this.payment = 0
      // }
      // const checkedCount = this.selectItem.filter(item => item).length
      // return checkedCount > 0 && checkedCount < this.selectItem.length
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

      <style lang="scss">
      @import "@core/scss/vue/libs/vue-select.scss";
      </style>
